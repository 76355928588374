<template>
    <el-dialog
        :visible.sync="show"
        width="45%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <!--    <div slot="title" class="header-title">-->
        <!--      <el-row :gutter="5">-->
        <!--        <el-col :span="16">-->
        <!--          <span class="title-name">回复</span>-->
        <!--        </el-col>-->
        <!--      </el-row>-->
        <!--    </div>-->
        <el-row class="mt-2">
            <el-col class="flex">
                <span class="reply-title">提问描述：</span>
                <span style="font-size: 20px; font-weight: bolder">{{
                    row.content
                }}</span>
            </el-col>
            <el-col class="flex mt-3">
                <span class="reply-title" style="width: 140px">回复：</span>
                <el-input
                    v-model="form.content"
                    type="textarea"
                    placeholder="请输入内容"
                    :autosize="{ minRows: 5, maxRows: 5 }"
                    maxlength="250"
                    show-word-limit
                />
            </el-col>
        </el-row>
        <div class="mt-2 flex justify-flex-end">
            <el-button type="primary" size="small" @click="commit"
                >提交</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
import { quiz } from "@/api";
export default {
    name: "Operate",
    components: {},
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            show: this.dialog,
            form: {
                content: "",
            },
        };
    },
    created() {},
    methods: {
        commit() {
            this.$axiosReq(
                quiz + this.row.id,
                null,
                {
                    content: this.form.content,
                },
                "post"
            )
                .then((res) => {
                    this.$message.success(res.msg);
                    this.close();
                    this.$emit("get-list");
                })
                .finally((e) => {});
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
    },
};
</script>

<style scoped>
.reply-div {
    min-height: 100px;
    border-bottom: 1px solid #3b3730;
}
.reply-title {
    font-size: 23px;
    color: #5a8cff;
    font-weight: bolder;
}
</style>
