<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<el-card>
			<el-row  >
                <el-col  :span="5">
                    <el-input style="width: 200px" v-model="condition.search" class="input searchInput" size="mini" suffix-icon="el-icon-search"
                              placeholder="搜索" @input="changeCondition" />
                </el-col>
				<el-col :span="6">
					<span class="text-primary text-pad-right">日期</span>
					<el-date-picker v-model="condition.date" type="daterange" class="input searchInput" range-separator="至"
					 start-placeholder="开始日期" end-placeholder="结束日期" clearable :unlink-panels="true" value-format="yyyy-MM-dd HH:mm:ss"
					 :default-time="['00:00:00','23:59:59']" size="mini" @change="changeCondition" />
				</el-col>

			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col :span="1">
								<vxe-button @click="getList">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="$headerStyle" :row-style="$rowStyle"
				 align="center" class="vxe-table-element" height="600" row-id="notice_id" :sort-config="{remote: true}"
				 :filter-config="{remote: true}" :checkbox-config="{reserve: true}" :tooltip-config="{enterable: false}" :data="table.list"
				 :loading="loading">
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="realName" title="姓名" />
					<vxe-table-column field="videoTitle" title="视频名称" />
					<vxe-table-column field="createTime" title="时间" />
					<vxe-table-column field="content" title="提问" show-overflow="title" />
					<vxe-table-column field="replyContent" title="回复内容" />
					<vxe-table-column title="操作">
						<template v-slot="{ row }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="!row.replyContent" @click.native="
										openDialog({
					                        title: '回复',
					                        addOr: 'reply',
					                    }, row)">回复</el-dropdown-item>
									<el-dropdown-item v-if="$hasBtn('sys:permissions:msgJudge:delete')" @click.native="
											remove({ del: 'single' }, row)
										">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
				 :page-size="table.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		quiz
	} from '@/api'
	import Operate from './Operate'
	export default {
		name: 'Index',
		components: {
			Operate
		},
		data() {
			return {
				loading: false,
				condition: {
					is_release: 2,
					search: '',
					date: [],
					start: '',
					end: ''
				},
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: {},
					info: {},
					refresh: false, // 每次刷新DOM
					refresh2: false, // 每次刷新DOM
					show: false,
					show2: false
				},
				company: [],
				loginInfo: {}
			}
		},
		created() {
			this.getList()
		},
		methods: {
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true
				this.dialog.show = true
				this.dialog.info = info
				this.dialog.row = row
			},
			changeCondition() {
				this.table.currentPage = 1
				this.getList()
			},
			getList() {
				this.loading = true
				this.$axiosReq(quiz, null, {
					videoTitle: this.condition.search,
					startTime: this.condition.date ? this.condition.date[0] : '',
					endTime: this.condition.date ? this.condition.date[1] : '',
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(res.data.totalElements)
				}).finally(() => {
					this.loading = false
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			refreshDialog() {
				this.dialog.refresh = false
			},
			remove(info, row) {
				this.$confirm("确认删除吗！！！", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$axiosReq(
							'/course/server/escort/web/videoQuiz/' + row.id,
							null,
							null,
							"delete"
						).then((res) => {
							this.$message.success(res.msg);
							this.getList();
						});
					})
					.catch(() => {
						this.$message.info("删除失败！！！");
					});
			},
		}
	}
</script>

<style scoped>

</style>
